import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
//import { auth } from '../../helpers/Firebase';
import { apiUrl } from '../../constants/defaultValues';
import axios from 'axios'
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => 
  await axios.post(`${apiUrl}login`, {email, password}).then(res => {
    console.log("res data", res.data);
    return res.data;
  }).catch(error => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
      const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
      
      if (loginUser.status == 1) {
        console.log("loginUser ==>", loginUser, history);
          localStorage.setItem('token', loginUser.token);
          localStorage.setItem('user_id', loginUser.data.user_id);
          localStorage.setItem('role', loginUser.data.role);
          yield put(loginUserSuccess(loginUser.data.user_id));
          history.push('/app/dashboard');
      } else {
          let loginMsg = 'Something went wrong with Login';
          if(loginUser.errors) 
              loginMsg = loginUser.errors[0].msg
          if(loginUser.message)
              loginMsg = loginUser.message      
          yield put(loginUserError(loginMsg));
      }    
  } catch (error) {
      yield put(loginUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }) {
  const { history } = payload
  try {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('role');
      history.push('/user/login')
  } catch (error) {
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => 
  axios.post(`${apiUrl}reset-password`, { code: resetPasswordCode, newpassword: newPassword })
    .then(res => {
      console.log("response", res.data)
      return res.data;      
    }).catch(error => error)

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (resetPasswordStatus.status == 1) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
