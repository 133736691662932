/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'عربى', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
}; 

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';

export const apiUrl = "https://api.bodyplus.com.sa/admin/";
export const apiFrontUrl = "https://api.bodyplus.com.sa/";
export const projectName = "Bodyplus";
export const S3storage = "https://bodyplus-v1.s3.me-south-1.amazonaws.com";
// export const imgBaseUrl = "https://stagingapi.bodyplus.com.sa"
export const imgBaseUrl = "https://bodyplus-com-sa.b-cdn.net"
// export const imgBaseUrl = "https://bodyplus.s3-accelerate.amazonaws.com/uploads"
export const shippingApiCreateUrl = "https://dev.aymakan.com.sa/api/v2/shipping/create";
export const shippingApiKey = "55604ebc388a742301ec14ae64c8b837-b04377dc-cc47-4e72-aa3c-9c2afbeb3d9a-2b902387b9899cf6fb66e11b3009af74/0f6857761dd66c8c9b44247f902875de/70f122ab-70b7-4d13-b268-84a9304a8ca4"
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
